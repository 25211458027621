<template>
  <div class="parent mt-2 bg-white rounded shadow">
    <section v-if="!isLoaded" class="h-96">
      <loading :active="!isLoaded" :is-full-page="false" />
    </section>
    <section class="w-full bg-white" v-else>
      <div class="scroll-view" v-if="splunkData.length">
        <infinite-loading @infinite="fetchVehicleSplunks">
          <div slot="no-more" class="mt-2"></div>
          <div slot="no-results" class="mt-2"></div>
        </infinite-loading>
        <div class="splunk-view" id="scroll-target">
          <div
            v-for="entry in splunkData"
            :key="entry.id"
            :id="`${entry.id}-elem`"
            :class="splunkFormatClass(entry)"
          >
            <span class="white-text">{{ getFormattedDate(entry) }}</span
            ><span class="divider"> | </span
            ><span class="green-text" :class="boldFormatClass(entry)">{{
              entry.data
            }}</span>
          </div>
          <!-- <div id="scroll-target"></div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'ViewVehicleTimeline',
  components: {
    // EmptySpace: () => import('@/components/placeholder/EmptySpace'),
    InfiniteLoading,
  },
  data() {
    return {
      splunkData: [],
      logLimit: 500,
      isLoaded: false,
      scrollOptions: {
        easing: 'ease-in',
        lazy: false,
        offset: -60,
        force: true,
        cancelable: false,
        x: false,
        y: true,
      },
    }
  },
  computed: {
    vehicleId() {
      return this.$route.params.id
    },
    logOffset() {
      return this.splunkData.length
    },
    logPath() {
      return (
        useEndpoints.vehicle.iotLogs.splunk(this.vehicleId) +
        `?limit=${this.logLimit}&offset=${this.logOffset}`
      )
    },
  },
  async mounted() {
    if (this.splunkData.length === 0) await this.fetchVehicleSplunks()
  },
  methods: {
    getFormattedDate(entry) {
      return new Date(entry['@timestamp']).toLocaleString()
    },
    scrollToBottom(elemId) {
      this.$scrollTo(`#${elemId}`, 200, this.scrollOptions)
    },
    boldFormatClass(entry) {
      return {
        'heavy-text': `${entry.data}`.includes('frame'),
      }
    },
    splunkFormatClass(entry) {
      return {
        'py-3': `${entry.data}`.includes('~~~'),
        'top-padding-3':
          `${entry.data}`.includes('frame') || `${entry.data}`.includes('Data'),
        'px-2': true,
      }
    },
    async fetchVehicleSplunks($state = null) {
      this.$http
        .get(this.logPath)
        .then((res) => {
          console.log(res.data)
          let parsedData = res.data['data']
          if (this.splunkData.length) {
            const toScroll = `${this.splunkData[-1].id}-elem`
            parsedData.forEach((entry) => this.splunkData.unshift(entry))
            // this.splunkData = this.splunkData.concat(parsedData)
            this.scrollToBottom(toScroll)
            console.log('Loaded later : Total ', this.splunkData.length)
          } else {
            this.splunkData = parsedData.reverse()
            console.log('Loaded first : Total ', parsedData.length)
            this.scrollToBottom(`${this.splunkData[-1].id}-elem`)
          }
          if ($state) {
            if (res.data['meta']['count']['total'] > this.splunkData.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.log(err)
          if ($state) $state.complete()
        })
        .finally(() => {
          if (!this.isLoaded) this.isLoaded = true
          console.log('DONE')
          // var container = this.$el.querySelector('#scroll-target')
          // container.scrollTop = container.scrollHeight
        })
    },
  },
}
</script>

<style lang="scss">
.splunk-view {
  // border: 5px solid yellow;
  word-wrap: break-word;
}
.parent {
  height: 800px;
  position: relative;
}
.scroll-view {
  // border: 5px solid red;
  max-height: 100%;
  overflow: auto;
  background-color: black;
  // opacity: 70%;
  // color: red;
}
.heavy-text {
  font-weight: bold;
}

.green-text {
  color: chartreuse;
  opacity: 0.9;
}

.divider {
  color: rebeccapurple;
  font-weight: bold;
}

.white-text {
  color: white;
  opacity: 0.85;
}

.top-padding-3 {
  padding-top: 3px;
}
</style>
